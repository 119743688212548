import React from "react";
import Nav from "./Nav";
import axios from "axios";
import { contractABI } from "./abi";

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(
  "wss://eth-mainnet.g.alchemy.com/v2/zYi9iUfRHxDz4Q3AoIqULNHmSntxHG3s"
);

const contractAddress = "0xaC8E76077E022410B60c8B1503C4dd4b0b04f598";
const tokenAddress = "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85";
const contractInstance = new web3.eth.Contract(contractABI, contractAddress);

export default DomainTable;

async function bidOnDomain(tokenID, cost) {
  contractInstance.methods
    .buy(tokenID)
    .send({
      to: contractAddress, // Required except during contract publications.
      from: window.ethereum.selectedAddress, // must match user's active address.
      value: "" + cost,
    })
    .then((tx) => {
      console.log("tx:", tx);
    });
}

// async function getDomainName(tokenID) {
//   const url =
//     "https://metadata.ens.domains/mainnet/0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/" +
//     tokenID;
//   const response = await axios
//     .get(url)
//     .then((response) => {
//       return response.data.name;
//     })
//     .catch((error) => {
//       console.log(error);
//       return "ENSDA";
//     });
//   return response;
// }

const statusMessage = {
  404: "Domain Not Found",
  410: "Domain Not Found",
  500: "Internal Server Error",
  501: "Internal Server Error",
};

async function getDomainName(tokenID) {
  const url = `https://metadata.ens.domains/mainnet/0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/${tokenID}`;
  try {
    const { status, data } = await axios.get(url);
    if (status === 200) {
      return data.name;
    }
  } catch ({ response }) {
    return statusMessage[response?.status] || "Network Error";
  }
}

function DomainTable() {
  const [domains, setDomains] = React.useState([]);
  const [domainNames, setDomainNames] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [currentTime, setCurrentTime] = React.useState(new Date());
  const [account, setAccount] = React.useState("");

  function getTimeLeft(endTime) {
    // return time left in DD:HH:MM:SS format
    const now = currentTime;
    const end = new Date(endTime * 1000);
    const diff = end - now;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    let daysLeft;
    if (days > 10) {
      daysLeft = days + "d ";
    } else if (days > 0) {
      daysLeft = "0 " + days + "d ";
    } else {
      daysLeft = "Ended";
    }
    return daysLeft;
  }

  function getDomainPrice(domain) {
    // If auction has ended, return 5 USD
    if (domain.expired) {
      return "5 USD";
    }
    // Otherwise, return current price
    return `${web3.utils.fromWei(domain.price, "ether")} ETH`;
  }

  async function getDomains() {
    const [activeDomains, unboughtDomains] = await Promise.all([
      contractInstance.methods.getActiveAuctions().call(),
      contractInstance.methods.getEndedAuctions().call(),
    ]);
  
    let domains = [...activeDomains, ...unboughtDomains];
    domains = domains.filter(({ active }) => active);
  
    domains = domains.map((domain) => ({
      ...domain,
      expired: domain.end < currentTime.getTime() / 1000,
    }));
  
    const domainNamesPromises = domains.map((domain) => 
      getDomainName(domain.tokenId)
    );
  
    const domainNamesResults = await Promise.all(domainNamesPromises);
  
    const validDomains = [];
    const domainNames = {};
  
    domains.forEach((domain, index) => {
      const domainName = domainNamesResults[index];
      if (domainName !== "Domain Not Found" && domainName !== "Internal Server Error") {
        domainNames[domain.tokenId] = domainName;
        validDomains.push(domain);
      }
    });
  
    setDomains(validDomains);
    setDomainNames(domainNames);
  }

  React.useEffect(() => {
    // get domains if not already loaded
    getDomains();
    setLoading(false);
  }, []);

  // subscribe to the AuctionCreated event and update the table
  React.useEffect(() => {
    contractInstance.events
      .AuctionCreated()
      .on("data", async (event) => {
        console.log("event fired:", event);
        if (domainNames[event.returnValues.tokenId] === undefined) {
          setDomains((domains) => [...domains, event.returnValues]);
          const domainName = await getDomainName(event.returnValues.tokenId);
          setDomainNames((domainNames) => ({
            ...domainNames,
            [event.returnValues.tokenId]: domainName,
          }));
        }
      })
      .on("error", console.error);
  }, []);

  // use effect to update time left every second
  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, [currentTime]);

  if (loading) {
    return (
      <div>
        <Nav account={account} setAccount={setAccount} />
        {/* centered loading spinner animation with loading text underneath*/}
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status"></div>
        </div>
      </div>
    );
  }

  // return a centered and stylized table of domains
  return (
    <>
      <Nav account={account} setAccount={setAccount} />
      <div className="container">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Domain</th>
              <th>Price</th>
              <th>Auction Days Left</th>
              <th>Buy</th>
            </tr>
          </thead>
          <tbody>
            {domains.map((domain) => (
              <tr key={domain.tokenId}>
                <td>
                  <a
                    style={{ textDecoration: "underline !important" }}
                    href={
                      "https://etherscan.io/token/" +
                      tokenAddress +
                      "?a=" +
                      domain.tokenId
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {domainNames[domain.tokenId]}
                  </a>
                </td>
                <td>{getDomainPrice(domain)}</td>
                <td>{getTimeLeft(domain.end)}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => bidOnDomain(domain.tokenId, domain.price)}
                  >
                    {domain.expired ? "Claim Now" : "Buy"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
