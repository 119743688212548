import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Footer from "./Footer";
import $ from "jquery";
import { ethers } from "ethers";

import DomainTable from "./DomainTable";
import { contractABI } from "./abi";

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(
  "wss://eth-mainnet.g.alchemy.com/v2/zYi9iUfRHxDz4Q3AoIqULNHmSntxHG3s"
);
// const web3 = createAlchemyWeb3(
//   "wss://eth-goerli.g.alchemy.com/v2/7EfQXZjgQZEY0MK_CCRLlG1S_TvOfzsr"
// );

// console.log(contractABI);

const contractAddress = "0x116631544421c1eafbd296aa84d85e6b26c087cd";
const contractInstance = new web3.eth.Contract(contractABI, contractAddress);

function MintPage() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [userBalance, setUserBalance] = useState(null);
  const [connButtonText, setConnButtonText] = useState("Connect Wallet");
  const [count, setCount] = useState(1);
  const [amount, setAmount] = useState(0);
  const [mintCost, setMintCost] = useState(0);

  const [imageAdress, setImageAdress] = useState("");

  const connectWalletHandler = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      console.log("MetaMask Here!");

      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          accountChangedHandler(result[0]);
          setConnButtonText("Mint NFTs");
          getAccountBalance(result[0]);
          $(".mint-btn").addClass(
            "buttons col-md-5 btn my-3 py-2 bg-white my-2"
          );
          $(".connect-btn").hide();
          $(".mint-btn").removeClass("hidden-btn");
          chainChangedHandler();
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    } else {
      console.log("Need to install MetaMask");
      setErrorMessage("Please install MetaMask browser extension to interact");
    }
  };

  // update account, will cause component re-render
  const accountChangedHandler = (newAccount) => {
    setDefaultAccount(newAccount);
    getAccountBalance(newAccount.toString());
  };

  const fetchActiveDomains = async () => {
    const activeDomains = await contractInstance.methods
      .getActiveDomains()
      .call();
    return activeDomains;
  };

  const getAccountBalance = (account) => {
    window.ethereum
      .request({ method: "eth_getBalance", params: [account, "latest"] })
      .then((balance) => {
        setUserBalance(ethers.utils.formatEther(balance));
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const chainChangedHandler = () => {
    window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x1" }],
    });
  };

  const list = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  // listen for account changes

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", accountChangedHandler);
      window.ethereum.on("chainChanged", chainChangedHandler);
    }
  }, []);

  return (
    <>
      <section className="">
        <div className="spacer"></div>
        <h1 className="text-center mt-5">BUY DOMAINS</h1>
        <div className="container disclaimers">
          <p className="text-center">
            <b>Disclaimer (Decentralized platform, use at your own risk)</b>
            {/* <br /> */}
            {/* Use at your own risk, always verify. You (the user) is responsible for due diligence, checking for name normalization, price oracle accuracy, blockchain updates, and all other information. */}
          </p>
          {/* <p className="text-center">
          <b>Selling a domain?</b> 
          <br />
          Simply send and wait, to sell simply send your domains to this smart contract: ENSDutchAuction.eth, wait 21 days or less, get paid only if it sells, you won't get paid if no sale occurs! you can't cancel listings or set a reserve! Once a domain is submitted any user can buy it during the first 21 days, or claim it for free once the auction is over, including the original seller of course! YOLO!
        </p>
        <p className="text-center">
          <b>Buying a domain?</b>
          <br />
          Connect your wallet with MetaMask or any compatible wallet of your choice, choose a domain from the list, make sure that the domain characters are proper, e.g non-ASCII domains + non-normalized + using L instead of i, using invisible characters ... etc. Be aware by double checking through block explorers, if you find a name you like, wait for the suitable price and buy it!
        </p> */}
        </div>
        <DomainTable />
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
      </section>
      {/* <div className="p-md-5 px-5">
        <div className="row row_imges">
          <div className="col">
            <img className="w-100 " src="/Assets/a.png" alt="" />
          </div>
          <div className="col">
            <img className="w-100 img__style21" src="/Assets/a.png" alt="" />
          </div>
          <div className="col">
            <img className="w-100 " src="/Assets/a.png" alt="" />
          </div>
          <div className="col">
            <img className="w-100 img__style21" src="/Assets/a.png" alt="" />
          </div>
          <div className="col">
            <img className="w-100 " src="/Assets/a.png" alt="" />
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
}

export default MintPage;
