import React, { useEffect } from "react";
import Nav from "./Nav";
import { contractABI, ensMainContractAbi } from "./abi";
import { Alchemy, Network } from "alchemy-sdk";
import { useState } from "react";
import ConnectBtn from "./ConnectBtn";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");

const web3 = createAlchemyWeb3(
  "wss://eth-mainnet.g.alchemy.com/v2/zYi9iUfRHxDz4Q3AoIqULNHmSntxHG3s"
);

const ensDutchAddress = "0x116631544421c1eafbd296aa84d85e6b26c087cd";

const contractInstance = new web3.eth.Contract(contractABI, ensDutchAddress);

//ens main contract
const ensContractAddress = "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85";
const ensContractInstance = new web3.eth.Contract(
  ensMainContractAbi,
  ensContractAddress
);

const config = {
  apiKey: "xPLTc7G-vZeGRECp1qD5HIaRVyX-jLvm",
  network: Network.ETH_MAINNET,
};
const alchemy = new Alchemy(config);

const Auction = () => {
  const [nfts, setNfts] = useState([]);
  const [account, setAccount] = useState("");
  const [loading, setLoading] = useState(true);
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  const auctionNFT = async (tokenId) => {
    try {
      //call the safeTransferFrom function from the ERC721 contract
      const tx = await ensContractInstance.methods
        .safeTransferFrom(account, ensDutchAddress, tokenId)
        .send({ from: account });

      console.log(tx);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        //get account
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];
        setAccount(account);
        setIsWalletConnected(true);

        // const walletAddress = "0x9a5838121598581cf0a5a48515B23b120F0Ca20d"; // mock wallet address
        const ensContractAddress = "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85";
        const nfts = await alchemy.nft.getNftsForOwner(account, {
          contractAddresses: [ensContractAddress],
        });

        setNfts(nfts.ownedNfts);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setIsWalletConnected(false);
      }
    };

    init();
  }, [account]);

  console.log(nfts, "ens");
  console.log(account, "account");

  return (
    <>
      <Nav account={account} setAccount={setAccount} />
      <div className="spacer"></div>
      <div className="py-3">
        <div className="container">
          <h1 className="text_heading">Auction Your Domains</h1>
          <div className="center-container"> {/* Add this div */}
            {isWalletConnected || //if account is connected
            account !== "" ? (
              <>
                {loading ? (
                  <div className="circle-loader"></div>
                ) : nfts.length === 0 ? (
                  <p className="" style={{fontSize: "1.5rem"}}>You do not own any ENS Domains.</p>
                ) : (
                  <div className="nft_cards_parent">
                    {nfts.map((nft, index) => (
                      <div key={index} className="nft_card">
                        <img src={nft.rawMetadata.image_url} alt="" />
                        <button
                          className="btn__1"
                          onClick={() => auctionNFT(nft.tokenId)}
                        >
                          Sell
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <>
                <p className="" style={{fontSize: "1.5rem"}}>Please connect your wallet to proceed with auctioning your ENS NFTs.</p>
                <ConnectBtn setAccount={setAccount} account={account} />
              </>
            )}
          </div> {/* Close the div */}
        </div>
      </div>
    </>
  );  
};

export default Auction;
