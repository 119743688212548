import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import "../index.css";
import ConnectBtn from "./ConnectBtn";

function Nav({ bgImg, hide, account, setAccount }) {
  const navigate = useNavigate();
  const [style, setStyle] = useState("navbar__down");

  const changeStyle = () => {
    setStyle("cont2");
  };

  const closeNav = () => {
    setStyle("navbar__down");
  };

  return (
    <>
      <div className="nav py-3">
        <div className="container nav__container">
          <div className="nav__left">
            <a href="/">
              <div>
                <img
                  style={{ width: "50px", height: "50px" }}
                  src="/Assets/nav-icon.png"
                  alt=""
                />
              </div>
              <span>ENS&nbsp;Dutch&nbsp;Auction</span>
            </a>
          </div>
          <div className="nav__right">
            {!hide && (
              <ul className="nav__menu">
                <li>
                  <a
                    href="https://medium.com/@ensdutchauction/ens-dutch-auction-about-d0809ee772e7"
                    target="__blank"
                  >
                    ABOUT
                  </a>
                </li>
                <li>
                  <a
                    href="https://medium.com/@ensdutchauction/ens-dutch-auction-faq-b8747a2c0889"
                    target="__blank"
                  >
                    FAQ
                  </a>
                </li>
                <li>
                  <a
                    href="https://ensdutchauction.substack.com/"
                    target="__blank"
                  >
                    NEWSLETTER
                  </a>
                </li>
              </ul>
            )}
            <div className="nav__social__icons">
              <a
                href="https://github.com/ensdutchauction"
                target="__blank"
                className="social__icon"
              >
                <div>
                  <i className="bi bi-github" />
                </div>
              </a>

              <a
                href="https://etherscan.io/enslookup-search?search=ensdutchauction.eth"
                target="__blank"
                className="social__icon"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="293.775"
                    height="293.667"
                    viewBox="0 0 293.775 293.667"
                    fill="black"
                  >
                    <g
                      id="etherscan-logo-light-circle"
                      transform="translate(-219.378 -213.333)"
                    >
                      <path
                        id="Path_1"
                        data-name="Path 1"
                        d="M280.433,353.152A12.45,12.45,0,0,1,292.941,340.7l20.737.068a12.467,12.467,0,0,1,12.467,12.467v78.414c2.336-.692,5.332-1.43,8.614-2.2a10.389,10.389,0,0,0,8.009-10.11V322.073a12.469,12.469,0,0,1,12.467-12.47h20.779a12.47,12.47,0,0,1,12.467,12.47v90.276s5.2-2.106,10.269-4.245a10.408,10.408,0,0,0,6.353-9.577V290.9a12.466,12.466,0,0,1,12.465-12.467h20.779A12.468,12.468,0,0,1,450.815,290.9v88.625c18.014-13.055,36.271-28.758,50.759-47.639a20.926,20.926,0,0,0,3.185-19.537,146.6,146.6,0,0,0-136.644-99.006c-81.439-1.094-148.744,65.385-148.736,146.834a146.371,146.371,0,0,0,19.5,73.45,18.56,18.56,0,0,0,17.707,9.173c3.931-.346,8.825-.835,14.643-1.518a10.383,10.383,0,0,0,9.209-10.306V353.152"
                        transform="translate(0 0)"
                        fill="black"
                      />
                      <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M244.417,398.641A146.808,146.808,0,0,0,477.589,279.9c0-3.381-.157-6.724-.383-10.049-53.642,80-152.686,117.405-232.79,128.793"
                        transform="translate(35.564 80.269)"
                        fill="black"
                      />
                    </g>
                  </svg>
                </div>
              </a>

              <a
                href="https://dune.com/ensdutchauction"
                target="__blank"
                className="social__icon"
              >
                <div>
                  <img src="https://dune.com/assets/glyph-32w.png" alt="" />
                </div>
              </a>
              <a
                href="https://twitter.com/ensdutchauction"
                target="__blank"
                className="social__icon"
              >
                <div>
                  <i className="bi bi-twitter" />
                </div>
              </a>
            </div>

            <ConnectBtn account={account} setAccount={setAccount} />
          </div>

          <div className="burger__icon" onClick={changeStyle}></div>
        </div>

        {/* Phone Screen */}
        <div className={style} style={{ backgroundImage: bgImg }}>
          <div>
            <div className="navbar__top">
              <div className="nav__left"></div>
              <div className="cross__icon" onClick={() => closeNav()}></div>
            </div>
            <div className="navbar__items">
              <ul className="nav__menu">
                <li>
                  <a
                    href="https://medium.com/@ensdutchauction/ens-dutch-auction-about-d0809ee772e7"
                    target="__blank"
                  >
                    ABOUT
                  </a>
                </li>
                <li>
                  <a
                    href="https://medium.com/@ensdutchauction/ens-dutch-auction-faq-b8747a2c0889"
                    target="__blank"
                  >
                    FAQ
                  </a>
                </li>
                <li>
                  <a
                    href="https://ensdutchauction.substack.com/"
                    target="__blank"
                  >
                    NEWSLETTER
                  </a>
                </li>
              </ul>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Nav;
