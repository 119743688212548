import React from "react";
import "../css/footer.css";

function Footer() {
  return (
    <section>
      <div className="container spacer">
        <h1 className="text-center">ENS DUTCH AUCTION</h1>
        <div className="icons__footers">
        <a
                href="https://github.com/ensdutchauction"
                target="__blank"
                className="footer__icon"
              >
                <div>
                  <i className="bi bi-github" />
                </div>
              </a>

              <a
                href="https://etherscan.io/enslookup-search?search=ensdutchauction.eth"
                target="__blank"
                className="footer__icon"
              >
                <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="293.775" height="293.667" viewBox="0 0 293.775 293.667" fill="black">
                  <g id="etherscan-logo-light-circle" transform="translate(-219.378 -213.333)">
                    <path id="Path_1" data-name="Path 1" d="M280.433,353.152A12.45,12.45,0,0,1,292.941,340.7l20.737.068a12.467,12.467,0,0,1,12.467,12.467v78.414c2.336-.692,5.332-1.43,8.614-2.2a10.389,10.389,0,0,0,8.009-10.11V322.073a12.469,12.469,0,0,1,12.467-12.47h20.779a12.47,12.47,0,0,1,12.467,12.47v90.276s5.2-2.106,10.269-4.245a10.408,10.408,0,0,0,6.353-9.577V290.9a12.466,12.466,0,0,1,12.465-12.467h20.779A12.468,12.468,0,0,1,450.815,290.9v88.625c18.014-13.055,36.271-28.758,50.759-47.639a20.926,20.926,0,0,0,3.185-19.537,146.6,146.6,0,0,0-136.644-99.006c-81.439-1.094-148.744,65.385-148.736,146.834a146.371,146.371,0,0,0,19.5,73.45,18.56,18.56,0,0,0,17.707,9.173c3.931-.346,8.825-.835,14.643-1.518a10.383,10.383,0,0,0,9.209-10.306V353.152" transform="translate(0 0)" fill="black"/>
                    <path id="Path_2" data-name="Path 2" d="M244.417,398.641A146.808,146.808,0,0,0,477.589,279.9c0-3.381-.157-6.724-.383-10.049-53.642,80-152.686,117.405-232.79,128.793" transform="translate(35.564 80.269)" fill="black"/>
                  </g>
                </svg>
                </div>
              </a>

              <a
                href="https://dune.com/ensdutchauction"
                target="__blank"
                className="footer__icon"
              >
                <div>
                  <img src="https://dune.com/assets/glyph-32w.png" alt="" />
                </div>
              </a>
              <a
                href="https://twitter.com/ensdutchauction"
                target="__blank"
                className="footer__icon"
              >
                <div>
                <i className="bi bi-twitter" />
                </div>
              </a>
        </div>
        <hr />
        <p className="text-center py-3 mb-3">
          @2022 ENS DUTCH AUCTION | ALL RIGHT RESERVED
        </p>
      </div>
    </section>
  );
}

export default Footer;
