import React, { useState, useEffect } from "react";
import { ethers } from "ethers";

function ConnectBtn({ account, setAccount }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [userBalance, setUserBalance] = useState(null);
  const [connButtonText, setConnButtonText] = useState("Connect Wallet");
  const [count, setCount] = useState(1);
  const [amount, setAmount] = useState(0);
  const [mintCost, setMintCost] = useState(0);

  const connectWalletHandler = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      console.log("MetaMask Here!");

      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          setAccount(result[0]);
          setConnButtonText("Wallet Connected");
          getAccountBalance(result[0]);

          chainChangedHandler();
        })

        .catch((error) => {
          setErrorMessage(error.message);
        });
    } else {
      console.log("Need to install MetaMask");
      setErrorMessage("Please install MetaMask browser extension to interact");
    }
  };

  console.log("account", account);

  // update account, will cause component re-render
  const accountChangedHandler = (newAccount) => {
    setDefaultAccount(newAccount);
    getAccountBalance(newAccount.toString());
  };

  const getAccountBalance = (account) => {
    window.ethereum
      .request({ method: "eth_getBalance", params: [account, "latest"] })
      .then((balance) => {
        setUserBalance(ethers.utils.formatEther(balance));
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const chainChangedHandler = () => {
    window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x1" }],
    });
  };

  const list = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  // listen for account changes

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", accountChangedHandler);
      window.ethereum.on("chainChanged", chainChangedHandler);
    }
  }, [account, accountChangedHandler]);

  return (
    <>
      <button onClick={connectWalletHandler} className="connect__btn">
        {account ? "Wallet Connected" : "Connect Wallet"}
      </button>
    </>
  );
}

export default ConnectBtn;
