import React from "react";
import Nav from "./Nav";
import { Link } from "react-router-dom";

function WhitlistArea() {
  const [account, setAccount] = React.useState("");
  const whitelist = new Date("July 13, 2023 00:00:00 UTC");
  var today = new Date();
  const diffTime = whitelist.getTime() - today.getTime();

  // Time calculations for days, hours, minutes and seconds. Set them as 0 if they are negative
  const [days, setDays] = React.useState(
    Math.max(Math.floor(diffTime / (1000 * 60 * 60 * 24)), 0)
  );
  const [hours, setHours] = React.useState(
    Math.max(
      Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      0
    )
  );
  const [minutes, setMinutes] = React.useState(
    Math.max(Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60)), 0)
  );
  const [seconds, setSeconds] = React.useState(
    Math.max(Math.floor((diffTime % (1000 * 60)) / 1000), 0)
  );
  const [timeUp, setTimeUp] = React.useState(diffTime < 0 ? true : false);

  //reduce the time by 1 second every second and update the state with the new time left until the end of the countdown date is reached (0) and then stop the timer and show the message that the countdown has ended (timeUp) and then stop the timer and show the message that the countdown has ended (timeUp)
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          if (hours === 0) {
            if (days === 0) {
              clearInterval(interval);
              setTimeUp(true);
            } else {
              setDays(days - 1);
              setHours(23);
              setMinutes(59);
              setSeconds(59);
            }
          } else {
            setHours(hours - 1);
            setMinutes(59);
            setSeconds(59);
          }
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    // print the time left
    return () => clearInterval(interval);
  }, [seconds, minutes, hours, days]);

  return (
    <>
      <Nav
        bgImg="linear-gradient(rgba(0, 0, 0, 0.475), rgba(0, 0, 0, 0.091)), url(/Assets/enbg.png)"
        account={account}
        setAccount={setAccount}
      />
      <div className="time ">
        <div className="spacer cta_container">
          <Link to="/domains">
            <button className="mint_btn">BUY DOMAINS</button>
          </Link>
          <Link to="/auction">
            <button className="mint_btn">SELL DOMAINS</button>
          </Link>
        </div>
        {/* <h1 className="mb-3 spacer">Launches In :</h1>
        <div className="d-flex gap-md-5 gap-4 time__whitlist">
          <div>
            <h3>{days}</h3>
          </div>
          <div>
            <h3>{hours}</h3>
          </div>
          <div>
            <h3>{minutes}</h3>
          </div>
          <div>
            <h3>{seconds}</h3>
          </div>
        </div>
        {timeUp ? (
          <Link to="/domains">
            <button className=" mint_btn ">BUY DOMAINS</button>
          </Link>
        ) : (
          <></>
        )} */}
      </div>
      <div></div>
    </>
  );
}

export default WhitlistArea;
