import React from "react";

function OurStory() {
  return (
    <section>
      <div className="story container spacer">
        <div className="row justify-content-between align-items-center ">
          <div className="col-md-7 content__mid">
            <h1 className="mb-4">WHAT'S THIS?</h1>

            <p>
              A domain liquidation platform, where you force your domain to expire earlier than it should, and you get paid if it sells within 21 days, works like the current ENS expiry system, where expired domains are auctioned for 21 days until they eventually become available for registration again, the difference here is that the seller is the one who gets paid instead of the DAO. So domain owners that are not using their domains, can not sell, and need liquidity can use this platform to liquidate and help buyers find great domains. The platform gives sellers a choice to either wait until the domain expires and is auctioned by the DAO ,which can take years if a domain is registered several years into the future, or auctioning the domain now.
            </p>
          </div>

          <div className="col-md-4 mobile__screen">
            <div className="main_div32">
              <img src="/Assets/a.png" alt="" className="w-100 content__img" />
              <div className="outer__div2 "></div>
              <div className="outer__div1 "></div>
              <div className="outer__div3 "></div>
              <div className="outer__div4 "></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurStory;
