import React from "react";
import "../css/faqs.css";
import Faq from "react-faq-component";

function Faqs() {
  const data = {
    title: "FAQ (How it works)",
    rows: [
      {
        title: "When is the official launch?",
        content: `It will be the 22nd of November, 2022`,
      },
      {
        title: "How do I sell my domain?",
        content: `You can sell your domain on the secondary market, or you can auction it off on our Dutch Auction platform.`,
      },
      {
        title: "How can I bid on a domain?",
        content: `After the auction starts, you can bid on the domain by selecting the domain you like and bidding on it with its live price. Once your bid is accepted, you will be the owner of the domain.`,
      },
      {
        title: "How can I view my domain once I buy it?",
        content: `Once you buy a domain, you can view it on the My Domains page. You can also view your domains on the ENS Manager.`,
      },
    ],
  };

  const styles = {
    bgColor: "transparent",
    titleTextColor: "white",
    titleTextSize: "48px",
    rowTitleColor: "red",
    // rowContentColor: 'grey',
    arrowColor: "white",
    rowTitleTextSize: "large",
    rowContentColor: "white",
    rowContentTextSize: "16px",
    rowContentPaddingTop: "10px",
    rowContentPaddingBottom: "10px",
  };

  const config = {
    animate: true,
    arrowIcon: "+",
    expandIcon: "+",
    collapseIcon: "-",
    tabFocus: true,
  };
  return (
    <section className="faqs">
      <div className="container">
        <div>
          <Faq data={data} styles={styles} config={config} />
        </div>
      </div>
    </section>
  );
}

export default Faqs;
