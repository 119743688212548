import "./App.css";
import JoinComunity from "./components/JoinComunity";
import "./css/story__team.css";
import OurStory from "./components/OurStory";
import WhitlistArea from "./components/WhitlistArea";
import Faqs from "./components/Faqs";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import MintPage from "./components/MintPage";
import Auction from "./components/Auction";

function App() {
  return (
    <>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <WhitlistArea />
                <OurStory />
                <div className="spacer"></div>
                <JoinComunity />
                <div className="spacer"></div>
                <Faqs />
                <Footer />
              </>
            }
          />
          <Route
            path="/domains"
            element={
              <>
                <MintPage />
              </>
            }
          />
            <Route
            path="/auction"
            element={
              <>
                <Auction />
              </>
            }
          />
        </Routes>
        
      </div>
    </>
  );
}

export default App;
